import { oneLine } from 'common-tags';
import { Button, PageTitle } from 'components';
import {
  LiveEventsIcon,
  OfficeHoursIcon,
  OnDemandVideoCourseIcon,
  OnDemandVideosIcon,
} from 'components/icons';
import React, { FC } from 'react';
import {
  SPECIAL_OFFER_ACTIVE,
  SPECIAL_OFFER_ANNUAL_ACTIVE,
} from 'utils/config';
import Benefit from './Benefit';
import connect from './connect';

import {
  Benefits,
  Container,
  Content,
  CTA,
  CTATitle,
  Fold,
  FoldContent,
  Pricing,
  PricingAnnualPromotion,
  PricingPromotion,
  Subtitle,
  SubtitleWrapper,
  SWDLogo,
  Title,
} from './styles';
import { Props } from './types';

const Premium: FC<Props> = ({ isPremium, plans, yearlySavings }) => {
  const webpSupported = !!Modernizr.webp;

  return (
    <Container>
      <PageTitle title="Go Premium" />
      <Fold webpSupported={webpSupported}>
        <FoldContent>
          <Title>
            Premium membership:
            <br />
            an increased level of learning and support
          </Title>
        </FoldContent>
      </Fold>
      <Content>
        {SPECIAL_OFFER_ANNUAL_ACTIVE ? (
          <PricingAnnualPromotion
            isAlreadyPremium={isPremium}
            plans={plans.map((plan) => ({
              id: plan.id,
              period: plan.interval,
              price: plan.amount / 100,
              savings: plan.interval === 'year' ? yearlySavings : undefined,
              metadata: { disabled: plan.metadata?.disabled ?? 'false' },
            }))}
          />
        ) : SPECIAL_OFFER_ACTIVE ? (
          <PricingPromotion
            isAlreadyPremium={isPremium}
            plans={plans.map((plan) => ({
              id: plan.id,
              period: plan.interval,
              price: plan.amount / 100,
              savings: plan.interval === 'year' ? yearlySavings : undefined,
              metadata: { disabled: plan.metadata?.disabled ?? 'false' },
            }))}
          />
        ) : (
          <Pricing
            isAlreadyPremium={isPremium}
            plans={plans.map((plan) => ({
              id: plan.id,
              period: plan.interval,
              price: plan.amount / 100,
              savings: plan.interval === 'year' ? yearlySavings : undefined,
              metadata: { disabled: plan.metadata?.disabled ?? 'false' },
            }))}
          />
        )}
        <SubtitleWrapper>
          <SWDLogo />
          <Subtitle>Discover the benefits of premium membership</Subtitle>
        </SubtitleWrapper>
        <Benefits>
          <Benefit
            description={oneLine`Data storyteller office hours are for exactly
            that. Come prepared with your specific questions or ready to screen
            share your example. We want you to be successful: this is your
            direct access to SWD team for the input your need and to get your
            questions answered!`}
            Icon={OfficeHoursIcon}
            summary={oneLine`Do you ever need quick input on a graph or slide,
            or wish you could run an idea or question by the SWD team?`}
            supertitle="Get SWD feedback"
            title="Data storyteller office hours"
            linkText="View office hour schedule"
            link="/office-hours"
          />
          <Benefit
            description={oneLine`These virtual sessions feature a variety of formats and content, each aimed to help you hone your data visualization and storytelling skills. Tune in to learn the strategies Cole and team teach organizations around the world, see interviews with experts, engage in conversations about topics of interest, get your questions answered, and more. Live events are recorded and can be accessed later on demand by premium members.`}
            Icon={LiveEventsIcon}
            summary={oneLine`Nothing compares to engaging in real-time data
            storytelling and this is your monthly opportunity to do so—from the
            comfort of your own computer.`}
            supertitle="Tune in real time"
            title="Live virtual events"
            linkText="See upcoming events"
            link="/live-events"
          />
          <Benefit
            description={oneLine`Ranging from short “how to” videos and exercise
            solution walk-throughs to longer, in-depth lessons and recorded live
            sessions, premium membership grants you access to a wide array of
            video learning content created by Cole and the SWD team. Self-serve
            to view topics of interest at your convenience.`}
            Icon={OnDemandVideosIcon}
            summary={oneLine`Seeing lessons employed effectively while hearing
            the thought process is an excellent way to learn. SWD videos bring
            some of the magic of our workshops directly to you.`}
            supertitle="Watch our lessons in action"
            title="Exclusive video content"
            linkText="Explore the video library"
            link="/videos"
          />
          <Benefit
            description={oneLine`With optional exercises to undertake you’ll have 
              opportunities to apply your new skills and reinforce your understanding 
              along the way with certificates of completion provided to acknowledge your achievement.`}
            Icon={OnDemandVideoCourseIcon}
            summary={oneLine`We are excited to announce our on-demand learning course: 
              Behind the Slides: good to great PowerPoint presentations is available to our 
              annual and lifetime premium members. Designed to enhance your PowerPoint skills, 
              this course of over 75 minutes, delves into real-world strategies and techniques 
              used to create compelling and effective slides at your own pace and from the comfort 
              of your own space.`}
            supertitle="Learn on your own"
            title="On-demand video course"
            linkText="Access today"
            link="/good-to-great-course"
          />
        </Benefits>
        <CTA webpSupported={webpSupported}>
          <CTATitle>Upgrade to premium membership today</CTATitle>
          <Button variant="premiumAccent" disabled={isPremium} to="/subscribe">
            Go premium!
          </Button>
        </CTA>
      </Content>
    </Container>
  );
};

export default connect(Premium);

import DefaultTag from 'components/Tag';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Info = styled.div<{ isVerticalVariant?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: solid 1px ${({ theme }) => theme.colors.greyBlue};

  ${({ isVerticalVariant }) => `
    padding: ${isVerticalVariant ? '0' : '2rem 3rem'};

    ${from.tablet`
      padding: ${isVerticalVariant ? '0' : '2rem 5.8rem'};
    `}
  `}
`;

export const Description = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.5rem;
  margin-top: 0;

  ${from.tablet`
    text-align: center;
  `}
`;

export const Features = styled.ul<{ isVerticalVariant?: boolean }>`
  list-style: none;
  width: fit-content;
  padding: 0;

  ${from.tablet`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 2.75rem;
    grid-row-gap: 0.5rem;
    margin: 0 auto;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}

  ${from.laptop`
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr 1fr;
  `}

   ${({ isVerticalVariant }) => `
    ${isVerticalVariant && 'display: flex !important;'}
    ${isVerticalVariant && 'flex-direction: column !important;'}
    ${isVerticalVariant && 'margin: 1rem 0 !important;'}
  `}
`;

export const Tag = styled(DefaultTag)`
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.judge};
  width: fit-content;
  margin-inline-start: 2rem;
`;

import PremiumBenefitsBlock from 'components/PremiumBenefitsBlock';
import React, { FC, memo, useCallback, useState } from 'react';
import Plan from './Plan';
import {
  AvailableText,
  Container,
  OfferText,
  Plans,
  PremiumTabCorporate,
  PremiumTabIndividual,
  PremiumText,
  PremiumTypeTabs,
  PromoTexts,
  Subtitle,
  TabText,
  Title,
  Wrapper,
} from './styles';
import { Props } from './types';

const PricingAnnualPromotion: FC<Props> = ({
  isAlreadyPremium,
  plans,
  ...rest
}) => {
  const [isCorporate, setIsCorporate] = useState(false);

  const setCorporateValueTrue = useCallback(() => setIsCorporate(true), [
    setIsCorporate,
  ]);
  const setCorporateValueFalse = useCallback(() => setIsCorporate(false), [
    setIsCorporate,
  ]);

  const getPlanPeriod = useCallback((period: string) => {
    switch (period) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Annual';
      default:
        return 'Lifetime';
    }
  }, []);

  return (
    <Container {...rest}>
      <Wrapper>
        <Title>Choose a plan</Title>
        <Subtitle>
          Become a premium member and get unlimited access to resources you
          won’t find anywhere else.
        </Subtitle>
        <PremiumTypeTabs>
          <PremiumTabIndividual
            $activeTab={!isCorporate}
            onClick={setCorporateValueFalse}
          >
            <TabText $activeTab={!isCorporate}>Individual Plan</TabText>
          </PremiumTabIndividual>
          <PremiumTabCorporate
            $activeTab={isCorporate}
            onClick={setCorporateValueTrue}
          >
            <TabText $activeTab={isCorporate}>Group Plan</TabText>
          </PremiumTabCorporate>
        </PremiumTypeTabs>
        {!isCorporate ? (
          <PromoTexts>
            <OfferText>Special sign up offer!</OfferText>
            <PremiumText>3 free months of Premium</PremiumText>
            <AvailableText>
              Available until January 31st for annual subscriptions
            </AvailableText>
          </PromoTexts>
        ) : null}
        <Plans>
          {plans
            .filter((plan) => plan.metadata?.disabled === 'false')
            .map(
              (plan, i) =>
                !(!plan.period && isCorporate) && (
                  <Plan
                    disabled={isAlreadyPremium}
                    id={plan.id}
                    index={i}
                    isCorporate={isCorporate}
                    key={plan.id}
                    period={getPlanPeriod(plan.period)}
                    price={plan.price}
                    savings={plan.savings}
                    subtitle={
                      isCorporate
                        ? `per seat per ${plan.period}`
                        : plan.period
                        ? `per ${plan.period}`
                        : 'one time'
                    }
                    specialOffer={plan.period === 'year' && !isCorporate}
                  />
                ),
            )}
        </Plans>
      </Wrapper>
      <PremiumBenefitsBlock />
    </Container>
  );
};

export default memo(PricingAnnualPromotion);

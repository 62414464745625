import { PageTitle } from 'components';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Content, Description } from './styles';

const UnsubscribeRoot: FC = () => {
  const title = 'Unsubscribed';

  return (
    <Container>
      <PageTitle title={title} />
      <Content>
        <Description>
          You’ve successfully unsubscribed from all SWD community notifications.
        </Description>
        <p>
          If this was a mistake or you’d like to adjust your preferences
          instead, you can always manage your{' '}
          <Link to="/members/me/settings/email-notifications">settings</Link> to
          customize the content you receive.
        </p>
        <p>
          You still remain an active member of the community should you wish to
          take on the monthly challenge, tackle an exercise or consume the
          variety of resources we have on offer.
        </p>
        <p>Thanks for being part of our community!</p>
      </Content>
    </Container>
  );
};

export default UnsubscribeRoot;

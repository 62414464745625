import { Header, Switch } from 'components';
import GuestRoute from 'containers/Common/GuestRoute';
import lazy from 'containers/Common/Lazy';
import ProtectedRoute from 'containers/Common/ProtectedRoute';
import { parse } from 'query-string';
import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route } from 'react-router-dom';
import { parseReturnTo } from 'utils/intent';
import Activity from './Activity';
import Challenges from './Challenges';
import { useConnect } from './connect';
import Conversations from './Conversations';
import Discover from './Discover';
import Events from './Events';
import Exercises from './Exercises';
import Feedback from './Feedback';
import Home from './Home';
import Login from './Login';
import Members from './Members';
import NotFound from './NotFound';
import OfficeHours from './OfficeHours';
import Onboarding from './Onboarding';
import Premium from './Premium';
import ResetPassword from './ResetPassword';
import Search from './Search';
import SetPasswordPremiumInvitation from './SetPasswordPremiumInvitation';
import SetSecondaryEmail from './SetSecondaryEmail';
import SignUp from './SignUp';
import { Container, Content, Footer, HomeLogo } from './styles';
import Support from './Support';
import { Props } from './types';
import Unsubscribe from './Unsubscribe';
import Videos from './Videos';

const Subscribe = lazy(() => import('./Subscribe'));

const Main: FC<Props> = () => {
  const {
    action,
    location,
    logOut,
    replace,
    signUpModalOpen,
    userAvatar,
    userName,
    userType,
    thereIsALiveEvent,
  } = useConnect();

  const { returnTo, ...query } = parse(location.search);

  return (
    <Container>
      <BreadcrumbsItem to="/">
        <HomeLogo title="SWD Logo" />
      </BreadcrumbsItem>
      <Header
        onLogOut={logOut}
        pathname={location.pathname}
        userAvatar={userAvatar}
        userType={userType ?? 'default'}
        isLive={thereIsALiveEvent}
        userName={userName}
      />
      <Content>
        <Switch action={action} location={location} replace={replace}>
          <GuestRoute component={Login} path="/login" />
          <GuestRoute component={ResetPassword} path="/reset-password" />
          <GuestRoute
            component={SetPasswordPremiumInvitation}
            path="/premium-invitation"
          />
          <GuestRoute component={SetSecondaryEmail} path="/secondary-email" />
          <GuestRoute component={SignUp} path="/sign-up" />
          <Route path="/onboarding">
            <Onboarding />
          </Route>
          {!signUpModalOpen && returnTo && typeof returnTo === 'string' && (
            <Redirect to={parseReturnTo(returnTo, query)} />
          )}
          <Route component={Home} exact={true} path="/" />
          <ProtectedRoute path="/activity">
            <Activity />
          </ProtectedRoute>
          <ProtectedRoute exact={true} path="/subscribe">
            <Subscribe />
          </ProtectedRoute>
          <ProtectedRoute path="/subscribe/corporate">
            <Subscribe />
          </ProtectedRoute>
          <Route path="/challenges">
            <Challenges />
          </Route>
          <Route path="/conversations">
            <Conversations />
          </Route>
          <Route path="/discover">
            <Discover />
          </Route>
          <Route component={Events} path="/live-events" />
          <Route path="/exercises">
            <Exercises />
          </Route>
          <Route path="/feedback">
            <Feedback />
          </Route>
          <Route path="/members">
            <Members />
          </Route>
          <Route component={OfficeHours} path="/office-hours" />
          <Route component={Premium} path="/premium" />
          <Route component={Search} path="/search" />
          <Route component={Videos} path="/videos" />
          <Route path="/unsubscribe">
            <Unsubscribe />
          </Route>
          <ProtectedRoute path="/support">
            <Support />
          </ProtectedRoute>
          <Route component={NotFound} path="/" />
        </Switch>
      </Content>
      <Footer />
    </Container>
  );
};

export default Main;

import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  overflow: hidden;
  padding-inline: 1.75rem;
  position: relative;

  ${from.mobileXL`
    border-radius: 0.25rem;
    padding-inline: 4.625rem;
  `};

  ${from.laptop`
    padding-inline: 10.625rem;
  `};
`;

export const Content = styled.div`
  ${container};
  position: relative;
  padding: 2rem ${({ theme }) => theme.wrapperPaddingH.mobile} 1.875rem;
  ${from.tablet`
    padding: 2rem ${({ theme }) => theme.wrapperPaddingH.desktop} 5rem;
  `};
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.5rem;
`;

import {
  Button,
  FullScreenContainer,
  SWDLogo as DefaultSWDLogo,
} from 'components';
import { TimesIcon } from 'components/icons';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { buttonReset, container } from 'styles/mixins';

export const Container = styled(FullScreenContainer)`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  padding: 0.525rem 1rem;
  align-items: center;
  flex: 1;
`;

export const CommunityTitle = styled(DefaultLink)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const SWDLogo = styled(DefaultSWDLogo)`
  width: 10.1875rem;
`;

export const CloseIcon = styled(TimesIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 1.8125rem;
  width: 1.25rem;
`;

export const GoBackButton = styled(Button)`
  ${buttonReset};
  :hover:not(:disabled) {
    background-color: transparent;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.darkPrimary};
  }
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing.sm};
`;

export const Title = styled.h2`
  margin: 0 0 1.25rem;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const Paragraph = styled.p`
  max-width: 58.125rem;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
`;

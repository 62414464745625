import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import { Props } from './types';
import UnsubscribeRoot from './UnsubscribeRoot';

const Unsubscribe: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Unsubscribe</BreadcrumbsItem>
      <Switch>
        <Route component={UnsubscribeRoot} path={path} />
      </Switch>
    </>
  );
};

export default Unsubscribe;

import React from 'react';

import Feature from 'components/Pricing/Feature';
import { Description, Features, Info, Tag } from './styles';
import { PremiumBenefitsBlockProps } from './types';

const PremiumBenefitsBlock: React.FC<PremiumBenefitsBlockProps> = ({
  isVerticalVariant = false,
}) => {
  return (
    <Info isVerticalVariant={isVerticalVariant}>
      {!isVerticalVariant ? (
        <Description>As a premium member, you will gain access to</Description>
      ) : null}
      <Features isVerticalVariant={isVerticalVariant}>
        <Feature>Live virtual events</Feature>
        <Feature>Exclusive content & discounts</Feature>
        <Feature>Office hours</Feature>
        <Feature>Exclusive video content</Feature>
        <Feature>
          On-demand video course
          <Tag
            title="ANNUAL & LIFETIME MEMBERS"
            name="ANNUAL & LIFETIME MEMBERS"
          />
        </Feature>
        <Feature>Early access</Feature>
      </Features>
    </Info>
  );
};

export default PremiumBenefitsBlock;
